import BigNumber from 'bignumber.js';
import { IEnrichedUniswapPosition } from '../types/uniswapTokens';
import { ComputedReserveData } from '../hooks/app-data-provider/useAppDataProvider';

export const getAmountToBorrow = (
  enrichedPosition?: IEnrichedUniswapPosition,
  tokenToBorrow?: ComputedReserveData,
  leverage?: number,
) => {
  if (!tokenToBorrow || !leverage) return BigNumber(0);
  const price = tokenToBorrow.priceInUSD;
  const positionValue = BigNumber(enrichedPosition?.marketReferenceCurrencyValue || 0)
    .plus(enrichedPosition?.token0.marketReferenceCurrencyFee || 0)
    .plus(enrichedPosition?.token1.marketReferenceCurrencyFee || 0);
  const reducedLeverage = BigNumber(leverage).minus(BigNumber(leverage).multipliedBy(0.005));

  const amountToBorrow = positionValue
    .multipliedBy(reducedLeverage.minus(1))
    .div(price)
    .multipliedBy(BigNumber(10).pow(tokenToBorrow.decimals))
    .minus(1)
    .decimalPlaces(0);

  return amountToBorrow.isGreaterThan(0) ? amountToBorrow : BigNumber(0);
}
