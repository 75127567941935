import { calculateInterestRate } from '@yldr/math-utils';
import { IPositionToken } from '../types/uniswapTokens';
import BigNumber from 'bignumber.js';
import { ComputedReserveData } from '../hooks/app-data-provider/useAppDataProvider';

export const getBorrowRate = (tokenToBorrow: IPositionToken | ComputedReserveData, usageRatio: BigNumber) => calculateInterestRate({
  variableRateSlope1: tokenToBorrow.variableRateSlope1,
  variableRateSlope2: tokenToBorrow.variableRateSlope2,
  optimalUsageRatio: tokenToBorrow.optimalUsageRatio,
  baseVariableBorrowRate: tokenToBorrow.baseVariableBorrowRate,
  usageRatio: usageRatio.toString()
});
