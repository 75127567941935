import React from 'react';
import { ModalContextType, ModalType, useModalContext } from 'src/hooks/useModal';
import { ScrollModal } from '../../../components/primitives/ScrollModal';
import { PositionModalWrapper } from '../FlowCommons/PositionModalWrapper';
import { LeveragePositionModalContent } from './LeveragePositionModalContent';

export const LeveragePositionModal = () => {
  const { type, args, close } = useModalContext() as ModalContextType<{
    tokenId: string;
  }>;

  return (
    <ScrollModal open={type === ModalType.LeveragePosition} setOpen={close}>
      <PositionModalWrapper
        tokenId={args.tokenId}
        title="Leverage"
      >
        {(params) => <LeveragePositionModalContent {...params} />}
      </PositionModalWrapper>
    </ScrollModal>
  );
};
