import BigNumber from 'bignumber.js';
import { calculateCompoundedRate, normalize, RAY_DECIMALS, SECONDS_PER_YEAR, valueToBigNumber } from '@yldr/math-utils';
import { getBorrowRate } from './getBorrowRate';
import { ComputedReserveData } from '../hooks/app-data-provider/useAppDataProvider';

export const getBorrowApyByAmount = ({
  amountToBorrow,
  tokenToBorrow,
}:{
  amountToBorrow?: BigNumber;
  tokenToBorrow?: ComputedReserveData;
}) => {
  if (!tokenToBorrow || !amountToBorrow) {
    return valueToBigNumber(0).toString();
  }

  const totalBorrowedReal = amountToBorrow
    .multipliedBy(tokenToBorrow.priceInUSD)
    .div(BigNumber(10).pow(tokenToBorrow.decimals));
  const totalLiquidity = tokenToBorrow.totalLiquidityUSD;
  const totalDebtAfter = valueToBigNumber(tokenToBorrow.totalVariableDebtUSD).plus(totalBorrowedReal);
  const usageRatio = totalDebtAfter.multipliedBy(10 ** 27).div(totalLiquidity);
  const borrowRate = getBorrowRate(tokenToBorrow, usageRatio);

  return normalize(calculateCompoundedRate({
    rate: borrowRate,
    duration: SECONDS_PER_YEAR
  }).multipliedBy(100), RAY_DECIMALS);
}
