import BigNumber from 'bignumber.js';
import { USD_DECIMALS } from '@yldr/math-utils';
import { MIN_POSITION_LEVERAGE } from '../ui-config/constants';
import { ComputedReserveData } from '../hooks/app-data-provider/useAppDataProvider';

export const calculateAvailableLeverage = ({ tokenA, tokenB, amountA, amountB, tokenToBorrow }: {
  tokenA: ComputedReserveData;
  tokenB: ComputedReserveData;
  amountA: string | number | BigNumber;
  amountB: string | number | BigNumber;
  tokenToBorrow?: ComputedReserveData;
}) => {
  const amountUsdTokenA = BigNumber(amountA || 0)
    .multipliedBy(tokenA.priceInMarketReferenceCurrency)
    .div(BigNumber(10).pow(USD_DECIMALS));
  const amountUsdTokenB = BigNumber(amountB || 0)
    .multipliedBy(tokenB.priceInMarketReferenceCurrency)
    .div(BigNumber(10).pow(USD_DECIMALS));

  const marketReferenceCurrencyValue = amountUsdTokenA.plus(amountUsdTokenB);

  const availableUsdTokenA = BigNumber(tokenA.availableLiquidityUSD);
  const availableUsdTokenB = BigNumber(tokenB.availableLiquidityUSD);

  const ltvA = BigNumber(tokenA.baseLTVasCollateral).div(10000);
  const ltvB = BigNumber(tokenB.baseLTVasCollateral).div(10000);

  const positionLtv = BigNumber.min(ltvA, ltvB);
  const maxLeverage = BigNumber(1)
    .div(BigNumber(1).minus(positionLtv))
    .multipliedBy(0.99);

  const availableLeverageA = BigNumber.min(maxLeverage, availableUsdTokenA.div(marketReferenceCurrencyValue).plus(1));
  const availableLeverageB = BigNumber.min(maxLeverage, availableUsdTokenB.div(marketReferenceCurrencyValue).plus(1));

  if (tokenA.underlyingAsset === tokenToBorrow?.underlyingAsset) return availableLeverageA;
  if (tokenB.underlyingAsset === tokenToBorrow?.underlyingAsset) return availableLeverageB;
  return BigNumber(MIN_POSITION_LEVERAGE);
}
