import { useMemo } from 'react';
import BigNumber from 'bignumber.js';

export const useLeveragePositionValues = ({
  liquidityTokenA = 0,
  liquidityTokenB = 0,
  marketReferenceCurrencyValue = 0,
  priceInMarketReferenceCurrencyA = 0,
  priceInMarketReferenceCurrencyB = 0,
  leverage = 1,
}: {
  liquidityTokenA?: number;
  liquidityTokenB?: number;
  marketReferenceCurrencyValue?: number;
  priceInMarketReferenceCurrencyA?: string | number;
  priceInMarketReferenceCurrencyB?: string | number;
  leverage?: number;
}) => {
  return useMemo(() => {
    const totalAmountUsd = BigNumber(marketReferenceCurrencyValue).multipliedBy(leverage);
    const amountToken0 = BigNumber(liquidityTokenA).multipliedBy(leverage);
    const amountToken1 = BigNumber(liquidityTokenB).multipliedBy(leverage);
    const amountUsdToken0 = amountToken0.multipliedBy(priceInMarketReferenceCurrencyA);
    const amountUsdToken1 = amountToken1.multipliedBy(priceInMarketReferenceCurrencyB);

    return {
      totalAmountUsd: totalAmountUsd.toNumber(),
      amountToken0: amountToken0.toNumber(),
      amountToken1: amountToken1.toNumber(),
      amountUsdToken0: amountUsdToken0.toNumber(),
      amountUsdToken1: amountUsdToken1.toNumber(),
    }
  }, [
    liquidityTokenA,
    liquidityTokenB,
    marketReferenceCurrencyValue,
    priceInMarketReferenceCurrencyA,
    priceInMarketReferenceCurrencyB,
    leverage,
  ]);
}
