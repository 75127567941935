import { useQuery } from '@tanstack/react-query';
import { BigNumber } from 'bignumber.js';
import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { useSharedDependencies } from '../../ui-config/SharedDependenciesProvider';
import { useRootStore } from '../../store/root';

export interface IGlobalRevenueFee {
  chainId: number;
  marketId: number;
  marketName: string;
  revenueFee: BigNumber;
}

const INITIAL_DATA: IGlobalRevenueFee[] = [];

export const useGlobalRevenueFeesQuery = () => {
  const { uiLeveragePositionService } = useSharedDependencies();
  const [chainId, isMarketsReady, marketData] = useRootStore((store) => [
    store.currentChainId,
    store.isMarketsReady,
    store.getCurrentChainMarketData(),
  ]);

  const { data, ...rest } = useQuery({
    queryFn: () =>  {
      const requests = marketData.map((item) => {
        return uiLeveragePositionService.getGlobalRevenueFee(chainId, item.addresses.leverageDataProviderAddress);
      });
      return Promise.all(requests);
    },
    queryKey: [QueryKeys.GLOBAL_REVENUE_FEES, chainId],
    enabled: Boolean(marketData) && isMarketsReady,
    refetchInterval: POLLING_INTERVAL,
    select: (response): IGlobalRevenueFee[] => response.map((item, index) => ({
      chainId,
      marketId: marketData[index].marketId,
      marketName: marketData[index].marketName,
      revenueFee: BigNumber(item.toString()),
    })),
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  }
}
