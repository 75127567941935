import { ToggleButtonGroup as MuiToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

export const ToggleButtonGroup = ({ children, ...rest }: ToggleButtonGroupProps) => (
  <MuiToggleButtonGroup
    {...rest}
    sx={{
      padding: '3px',
      gap: '4px',
      borderRadius: '6px',
      border: '1px solid rgba(255, 255, 255, 0.06)',
      backgroundColor: '#26262B',
      ...rest.sx,
    }}
  >
    {children}
  </MuiToggleButtonGroup>
);
